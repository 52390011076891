@import 'daterangepicker_dark';
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700);
$fa-font-path: '../webfonts' !default;
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'), url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
    url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'), url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

:root {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
html {
  font-family: sans-serif;
}
body {
  font-family: Nunito, sans-serif;
}

.word-wrap {
  word-wrap: break-word;
}

.logotype {
  width: 147px;
  height: 40px;
}

.ellipsis span {
  padding-left: 0.3em;
}

.search-form {
  .search-form-type {
    cursor: pointer;
  }

  img.search-icon {
    height: 26px;
    width: auto;
    top: 2px;
    left: 2px;
    position: absolute;
  }

  .fa.search-icon {
    position: relative;
    top: 6px;
    left: 6px;
    text-shadow: 1px 1px 2px black, 0 0 1em;
  }
}

ol.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
}

#reportrange {
  display: inline-block;
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.header-title {
  line-height: 2rem;
}
//
//.dropdown-toggle{
//  &::after{
//    margin-left: 0;
//  }
//}

.navbar-nav-search {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: 100%;
}

.fontsize35rem {
  font-size: 3.5rem;
}

.fontsize25rem {
  font-size: 2.5rem;
}

.search-form {
  width: 100%;
  .input-group {
    width: 100%;
  }
}

body.dark {
  background-color: #57626b;
  color: #d6d6d6;

  header {
    &.border-bottom {
      border-bottom: none !important;
    }
  }

  input,
  input:focus,
  select,
  select:focus {
    background-color: #434e57;
    border-color: #758591;
    color: #d6d6d6;
  }

  a:not(.dropdown-item),
  .more-text a:not([href]):not([tabindex]).more-link {
    color: #a1c9d7;
    &:hover {
      color: #afdae8;
    }
  }
  a.badge {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  .btn {
    color: #fff;
    .btn-default {
    }
  }

  .card {
    color: #d6d6d6;
    background: #434e57;
    border: 1px solid #434e57;

    .card-header {
      background-color: #343d46;
    }
  }

  .table {
    color: #fff;
    &.table-hover {
      tbody {
        tr:hover {
          color: #e5e5e5;
        }
      }
    }
  }
}

.nav-item-ad {
  .nav-link-ad {
    margin-left: 10px;
    //margin-right: 5px;
    padding-top: 7px;
    padding-bottom: 5px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    position: relative;
    background-color: #17a2b8;
    color: #fff;
    sup {
      position: absolute;
      top: 0;
      right: 1px;
    }
  }
}
.open-btn-ad {
  background-color: #17a2b8;
  color: #fff;
  margin-right: 5px;
  sup {
    //position: absolute;
    //top: 0;
    //right: 1px;
  }
}
#buttonFromHorse {
  @media screen and (max-width: 455px) {
    display: none;
  }
}
.container-tree {
  // height: 40px;
}
.line-tree {
  position: relative;
  padding-top: 20px;
}
.line-tree::before {
  content: '';
  border-left: 2px solid black;
  position: absolute;
  height: 100%;
  top: 0;
  left: -30px;
}
.line-tree::after {
  content: '';
  border-bottom: 2px solid black;
  position: absolute;
  left: -30px;
  top: 30px;
  width: 20px;
}
.show-tree {
  height: fit-content;
}