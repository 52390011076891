$fa-font-path: '~@fortawesome/fontawesome-free/webfonts'
@import '~@fortawesome/fontawesome-free/scss/regular'
@import '~@fortawesome/fontawesome-free/scss/solid'
@import '~@fortawesome/fontawesome-free/scss/brands'
@import '~@fortawesome/fontawesome-free/scss/v4-shims'
@import '~@fortawesome/fontawesome-free/scss/fontawesome'
@import '~bootstrap/dist/css/bootstrap'
@import "~daterangepicker/daterangepicker"
//@import '~@bitquery/graph/dist/graphs.min'
@import "../../assets/stylesheets/application"



.badge.badge-secondary.open-btn
  background-color: #28a745 !important
  color: #fff !important
  padding: 5px 10px !important
  border-radius: 9px !important
  font-size: 12px !important
  font-weight: 400 !important
  cursor: pointer !important
  text-align: center !important
  display: inline-block !important

  &:hover
    background-color: #218838 !important

  &:focus
    outline: none !important
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5) !important

a
  text-decoration: none
  
svg[visibility="hidden"]
  position: absolute
  width: 0
  height: 0

.address
  background-color: #888
  padding: 5px
  border-radius: 10px
  max-width: 150px
  overflow: hidden
  text-overflow: ellipsis
  color: #efefef
  font-weight: bold

#chains
  padding: 10px
  min-width: 300px
  cursor: pointer

.add-connection
  margin: 0.25rem 1.5rem

.tabulator
  font-size: 16px !important

.live-header
  position: absolute
  right: 23px
  top: 15.3px
  color: green
  font-wiegh: bold

.blnkr
  font-size: 5px
  position: relative
  top: -2px
  left: 3px
  display: inline-block
  width: 5px
  height: 5px
  border-radius: 50%

.blink
  animation: blinker 1s linear infinite

@keyframes blink
  50%
    opacity: 0

.blinker
  position: absolute
  right: 58px
  top: 15px
  border-radius: 50%
  transform: translateY(-50%)
  width: 5px
  height: 5px
  background-color: green
  animation: blinker 1s linear infinite

@keyframes blinker
  50%
    opacity: 0

#greenButton
  background-color: #1c87c9
  -webkit-border-radius: 60px
  border-radius: 60px
  border: none
  color: #eeeeee
  display: none
  font-family: sans-serif
  font-size: 20px
  padding: 10px 10px
  text-align: center
  text-decoration: none

@keyframes glowing
  0%
    background-color: #2ba805
    box-shadow: 0 0 5px #2ba805
  50%
    background-color: #49e819
    box-shadow: 0 0 20px #49e819
  100%
    background-color: #2ba805
    box-shadow: 0 0 5px #2ba805

#greenButton
  animation: glowing 1300ms infinite

.underline-blue-font
  text-decoration: underline
  color: blue

.gold-border
  border: 3px solid gold

.orange-background
  background-color: orange

.orchid-background
  background-color: orchid


.showGreenLight
  display: block

.open-btn.badge:empty
  display: inline-block

.lds-dual-ring
  display: inline-block
  width: 120px
  height: 120px
  position: absolute
  top: 50%

  &:after
    content: " "
    display: block
    width: 64px
    height: 64px
    margin: 8px
    border-radius: 50%
    border: 6px solid #0570f2
    border-color: #0570f2 transparent #0570f2 transparent
    animation: lds-dual-ring 1.2s linear infinite

@keyframes lds-dual-ring
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)


//tree
$fontAwesomeFree: "Font Awesome 5 Free"
$fontWeightBold: 900
$opacity: 0.7
$colorGray: #9AA1A6
$borderStyle: 2.5px dotted $colorGray

// Common styles for the tree
.ul-tree, .li-tree, #tree
  position: relative
  list-style: none
  line-height: 1.6em

  #tree &
    > .li-tree:after, > .li-tree:before
      display: none

  .li-tree
    &.last-child:after
      display: none

    &:before, &:after
      position: absolute
      left: -1.9em
      content: ''
      width: 0.65em
      opacity: $opacity

    &:before
      top: 0
      height: 0.93em

    &:after
      bottom: 0
      height: 100%

  .summary-tree
    &.no-children:before
      display: none

    &:before
      position: absolute
      left: -1.35em
      font-size: 20px
      font-family: $fontAwesomeFree
      content: "\25B8"
      font-weight: $fontWeightBold
      background: inherit
      opacity: $opacity
      color: $colorGray
      display: block
      width: 20px
      height: 20px
      z-index: 100500
      text-align: center
      line-height: .80em

    details[open] &
      &:before
        content: "\25BE"

// Styles for summary
.summary-tree
  cursor: pointer
  list-style: none

  &.no-children
    cursor: default

    &:hover
      color: inherit

  &::marker, &::-webkit-details-marker
    display: none

  &.no-children:before
    font-family: $fontAwesomeFree
    content: "\25BE"
    font-weight: $fontWeightBold
    opacity: $opacity

// Reset styles
.resetcss-tree
  list-style: none
  padding-left: 27px

// Styles for nested elements
.ul-tree
  line-height: 1.5em
  padding-inline-start: 28px
  padding-left: 23px

// Styles for text
.event-tree, .content-tree
  display: flex
  align-items: center
  gap: 0 5px

.content-tree
  gap: 0 9px
  flex-wrap: wrap
  padding: 0 2px

.no-children
  &:hover
    opacity: 1

  &::before
    font-family: $fontAwesomeFree
    content: "\25BE"
    font-weight: $fontWeightBold
    opacity: $opacity

// Styles for tree buttons
.button-container
  display: flex
  flex-direction: row
  gap: 0 20px
  padding: 0px 0px 8px 22px

.collapse-button-tree, .expand-button-tree
  details
    transition: all 0.3s ease
  &:focus
    outline: none
    box-shadow: none

.text-block, .value-tree
  display: flex
  gap: 3px 5px
  overflow: hidden
  text-overflow: ellipsis
  max-width: 200px

  //graph
.tooltip-graph
  position: absolute
  display: none
  background: inherit
  border: 1px solid $colorGray
  border-radius: 5px
  padding: 3px

    // block-buttons
.block-buttons
  height: 16px
  width: 19px
  padding: 0
  font-size: 11px
  vertical-align: middle
  margin: 0 6px 6px 6px
// buttons in frame

.hide
  display: none

.button-pressed
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.5)
  filter: brightness(85%)

.button-transition
  transition: filter 0.3s ease

@keyframes fadeIn
  from
    opacity: 0
    transform: translateY(-37px)
  to
    opacity: 1
    transform: translateY(0)


.tr-animate
  animation: fadeIn 0.3s ease


